export class GeneralTransfer {
    message: string;
    isOK: boolean;
    keyName: string;
    keyValue: any;
}

export class PaginationModel {

    public pageIndex: number;
    public totalItems: number;
    public items: Array<any>;
}


interface Dictionary<T> {
    [key: string]: T;
}

export class PagingAndFilteringInfo {

    index: number;
    size: number;
    orderBy: string;
    isAsc: boolean;
    fields: Dictionary<any> = {};
}
